.MuiOutlinedInput-input{
    padding: 12px 14px!important;
}
.MuiInputBase-input{
    font-size: 14px!important;
}
.MuiAutocomplete-inputRoot{
    padding: 2px!important;
}
.MuiChip-root{
    height: 27px!important;
}
.form-control{
    /* display: inline-block!important; */
    margin-bottom: 10px!important;
}
::-webkit-file-upload-button {
    background: #6ed1ff;
    color: white;
    padding: 8px!important;
    border: none;
    border-radius: 8px;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  p{
    font-family: sans-serif;
    font-size: 14px;
  }
 
  .MuiCheckbox-colorPrimary.Mui-checked{
    color: #546e7a!important;
  }
  .MuiInputBase-root{
    line-height: 1em!important;
  }
  .tox-editor-header{
    z-index: 0!important;
  }
  /* .makeStyles-active-66 {
    background-color: #e4e4e4;
  } */